import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
  






  
  
  
      `}
  />
)

export default Fonts